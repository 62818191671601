.section-productdetails {
  color: var(--title-color);
}

.section-productdetails .nav .nav-link {
  color: var(--secondary-color);
}

.section-productdetails .nav .nav-link:hover {
  color: var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
}

.section-productdetails .breadcrumb {
  font-size: 14px;
}

.section-productdetails .breadcrumb .breadcrumb-item a {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 600;
}
.custom-switch-lg {
  transform: scale(1.4); /* Increase the size by adjusting the scale factor */
  transform-origin: left;
}

.section-productdetails p {
  color: var(--secondary-color);
}

.section-productdetails tr {
  color: var(--secondary-color);
}

.section-productdetails th {
  color: var(--title-color);
  font-weight: 400;
  width: 70%;
  padding: 11px;
  font-size: 14px;
}

.section-productdetails td {
  font-size: 14px;
}

.table > :not(caption) > * > * {
  padding: 11px !important;
}

.section-productdetails .productdetails-img {
  display: flex;
}

.section-productdetails .imgOption ul {
  padding: 0%;
}

.section-productdetails .imgOption ul li {
  list-style: none;
  padding-bottom: 8px;
}

.section-productdetails .imgBox {
  padding-left: 8px;
  width: 100%;
}

.section-productdetails .imgBox img {
  width: 100%;
}

.section-productdetails .counter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.section-productdetails h1 {
  font-weight: 700;
}

.section-productdetails h2 {
  font-weight: 700;
}

.section-productdetails h6 {
  font-weight: 700;
  color: var(--primary-color);
}

.section-productdetails h5 {
  font-weight: 500;
}

.section-productdetails .btn-success {
  background-color: var(--primary-color);
  border: none;
  padding: 16px 24px;
  font-weight: 700;
  font-size: 16px;
}

.section-productdetails .btn-success:hover {
  background-color: var(--primary-color-hover);
}

.counter input {
  width: 50px;
  border: 0;
  /* line-height: 30px; */
  font-size: 24px;
  text-align: center;
  background: var(--secondary-color);
  color: var(--white-color);
  appearance: none;
  outline: 0;
  padding: 8px;
  border: 1px solid var(--secondary-color);
}
.counter span {
  display: block;
  font-size: 24px;
  padding: 8px 20px;
  cursor: pointer;
  color: var(--secondary-color);
  user-select: none;
  border: 1px solid var(--secondary-color);
}

.section-productdetails .product-description p {
  margin: 0%;
}

.back-to-product {
  text-decoration: none;
  color: var(--secondary-color);
}

.back-to-product:hover {
  color: var(--primary-color);
}

/* .image-gallery .image-gallery-slide .image-gallery-image {
    height: 50%;
} */
/* .image-gallery-content .image-gallery-slide .image-gallery-image{
    height: 50%;
} */

@media (max-width: 767px) {
  .section-productdetails .product-description {
    margin-top: 48px;
  }
}
