/* .add .css-mkazzp-MuiButtonBase-root-MuiButton-root{
    color: var(--white-color);
    background-color: var(--primary-color) !important;
    border: none;
    padding: 12px 20px;
    font-weight: 700;
    border-radius: 8px;
    
}

.add .css-mkazzp-MuiButtonBase-root-MuiButton-root:hover{
    color: var(--white-color);
    background-color: var(--primary-color-hover);
    cursor:pointer;
} */

/* .filter .css-1w1rijm-MuiButtonBase-root-MuiButton-root {
    background-color: var(--title-color-bg);
    border: none;
    padding: 16px 24px;
    font-weight: 700;
    font-size: 20px;
    color: var(--white-color);
}

.filter .css-1w1rijm-MuiButtonBase-root-MuiButton-root:hover {
    background-color: var(--secondary-color);
} */

td p{
    min-width: max-content;
    margin-bottom: 0%;
    color: var(--secondary-color);
}

/* .stock .css-ke5b6m-MuiButtonBase-root-MuiButton-root { //donload btn
    color: var(--primary-color);
    background-color: var(--primary-color-bg);
    border: none;
    padding: 12px 18px;
    font-weight: 700;
    font-size: 16px;
    box-shadow: none;
} */

/* .stock .css-ke5b6m-MuiButtonBase-root-MuiButton-root:hover {
    background-color: var(--primary-color);
    cursor:pointer;
    color: var(--white-color);
} */

/* Modify button */
/* .stock .css-sxix9q-MuiButtonBase-root-MuiButton-root{
    color: var(--white-color);
    background-color: var(--title-color-bg);
    border: none;
    padding: 12px 20px;
    font-weight: 700;
    font-size: 16px;
}

.stock .css-sxix9q-MuiButtonBase-root-MuiButton-root:hover {
    color: var(--white-color);
    background-color: var(--secondary-color);
    cursor:pointer;
} */

.stock a{
    color: var(--white-color);
    text-decoration: none;
}

.stock a:hover{
    color: var(--white-color);
    text-decoration: none;
}

/* .css-1ut81e1-MuiPaper-root{
    width: 100% !important;
} */

.storageLink a{
    color: var(--secondary-color);
}

.storageLink a .active{
    color: var(--primary-color);
    border-bottom: 1px solid var(--primary-color);
}

.storageLink a:hover{
    color: var(--primary-color);
    border-bottom: 1px solid var(--primary-color);
} 

