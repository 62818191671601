.css-18sg6k4-MuiPaper-root-MuiDrawer-paper{
    overflow-x: hidden;
}

.css-2dfzdb-MuiDrawer-docked .MuiDrawer-paper{
    padding-bottom: 108px;
}

.drawer .success{
    background-color: var(--primary-color);
    border-radius: 4px;
    width: 0px;
}

.drawer .success:hover {
    background-color: var(--primary-color-hover);
}

.css-1nkgpoo-MuiStack-root{
    position: fixed;
    bottom: 0;
    text-align: center;
    background-color: var(--white-color) !important;
    width: 300px;
    padding: 32px !important;
    margin: 0% !important;
    width: 383px;
}

.css-fiov70 svg:hover{
    cursor: pointer;
    
}

.add .btn{
    color: var(--white-color);
    background-color: var(--primary-color) !important;
    border: none;
    padding: 12px 20px;
    font-weight: 700;
    border-radius: 8px;
    
}

.add .btn:hover{
    color: var(--white-color);
    background-color: var(--primary-color-hover) !important;
    cursor:pointer;
}