
.image-box{
    overflow: hidden;
    height: 120px;
}

.image-box .product-img{
    /* height: 40px; */
    height: 100px;
    transition: 0.3s ease-in-out;
}

.image-box .product-img:hover{
    transform: scale(1.4);
    z-index: 2;
    transition: 0.3s ease-in-out;
}

.add .btn-primary{
    color: var(--white-color);
    background-color: var(--primary-color);
    border: none;
    padding: 12px 20px;
    font-weight: 700;
}

.add .btn-primary:hover{
    color: var(--white-color);
    background-color: var(--primary-color-hover);
    cursor:pointer;
}

.add .btn-primary:first-child:active{
    color: var(--white-color);
    background-color: var(--primary-color);
    cursor:pointer;
}

td p{
    min-width: max-content;
    margin-bottom: 0%;
    color: var(--secondary-color);
}

.stock .btn-success {
    color: var(--primary-color);
    background-color: var(--primary-color-bg);
    border: none;
    padding: 12px 20px;
    font-weight: 700;
}

.stock .btn-success:hover {
    background-color: var(--primary-color);
    cursor:pointer;
}

.stock .btn-secondary {
    color: var(--white-color);
    background-color: var(--title-color-bg);
    border: none;
    padding: 12px 20px;
    font-weight: 700;
}

.stock .btn-secondary:hover {
    background-color: var(--secondary-color);
    cursor:pointer;
}

.MuiTablePagination-toolbar{
    align-items: baseline !important;
}


