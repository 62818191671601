.section-productdetails{
    color: var(--title-color);
}

.main-page{
    margin-top: 120px;
}

/* .top-nav{
    position: fixed;
    width: 100%;
    top: 24px;
} */

.image-gallery-slides {
    line-height: 0;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    text-align: center;
    height: 500px;
    object-fit: cover;
    align-items: center;
    display: flex;
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    height: 120px;
    width: 24px;
}



.div-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
}

.div-price{
    display: flex;
    align-items: center;
}

.div-discount{
    padding: 16px;
    background-color: #F5FBF8;
    border: 1px solid var(--primary-color);
    border-radius: 8px;
}

.coupon{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.coupon-name{
    display: flex;
    align-items: center;
}

.main-button:hover{
    background-color: #1e7551;
}