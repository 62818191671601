.main-section {
  margin-top: 55px;
  background: white;
  padding-top: 25px;
}

.form-title {
  background-color: #f2f2f2;
  padding: 12px 12px 12px 20px;
  font-weight: 700;
  color: var(--title-color);
}

.section-adminform {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.section-adminform .form-switch .form-check-input {
  height: 2rem;
  width: 4rem;
  box-shadow: none;
}

.section-adminform .form-check-input:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  box-shadow: none;
}

.section-adminform .form-label {
  color: var(--primary-color);
  font-weight: 700;
}

.section-adminform .form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
  width: 320px;
}

.section-adminform .btn-success {
  background-color: var(--green-color);
  border: none;
  padding: 16px 24px;
  font-weight: 700;
  font-size: 20px;
}

.section-adminform .btn-success:hover {
  background-color: var(--green-color-hover);
}

.section-adminform .btn-danger {
  background-color: var(--red-color);
  border: none;
  padding: 16px 24px;
  font-weight: 700;
  font-size: 20px;
}

.productErrorMessage {
  color: var(--red-color);
  order: 1px solid var(--red-color);
}

.section-adminform .btn-danger:hover {
  background-color: var(--red-color-hover);
}

.section-adminform .sec-row {
  display: inline;
  position: relative;
}

/* .section-adminform .sec-row:hover img{
    filter: blur(3px);
} */

.img-list {
  display: inline;
}

.close-icon {
  color: var(--red-color);
  position: relative;
  top: -32px;
  right: 12px;
  cursor: pointer;
}

.doc-icon {
  color: var(--red-color);
  position: relative;
  top: -32px;
  right: 12px;
  cursor: pointer;
}

/* .section-adminform span{
    display: block;
    position: absolute;
    bottom: -12px;
    left: 28px;
    cursor: pointer;
    color: var(--red-color);
    background-color: #fff;
    border-radius: 50px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
} */

/* .section-adminform .sec-row:hover span{
    display: block;
    
} */

.section-adminform .css-jayakb-MuiFormControl-root {
  width: 100%;
}

.backBar a {
  text-decoration: none;
  color: var(--title-color);
}

.backBar a:hover {
  color: var(--primary-color);
}

.backBar h3 {
  color: var(--title-color);
  margin-bottom: 0%;
  text-transform: uppercase;
}

.inputErrorMsg {
  margin: 4px 0 0 0;
  font-size: 13px;
  color: #e64646;
}

.custom-select select {
  color: var(--title-color) !important;
}

.section-adminform .dropdown-chip span {
  display: block;
  position: static;
  bottom: 0;
  left: 0;
  color: var(--title-color);
  background: none;
  border-radius: 50px;
  box-shadow: none;
}

/* .css-1u3bzj6-MuiFormControl-root-MuiTextField-root label:focus{
    color: #e64646 !important;
} */

/* .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
    color: #fff !important;
    font-size: large;
} */

.button-addClient a {
  text-decoration: none;
}

@media (max-width: 576px) {
  .button-add-client {
    margin-top: 16px !important;
  }
}

/* .css-6hp17o-MuiList-root-MuiMenu-list {
    margin: 0;
    padding: 0;
    position: relative;
    padding-top: 8px;
    padding-bottom: 8px;
    outline: 0;
    display: flex;
    flex-wrap: wrap;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-disabled{
    width: 100%;
    background-color: var(--white-color) !important;
    color: var(--secondary-color) !important;
    padding: 0px !important;
    margin: 12px !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
    background-color: var(--primary-color) !important;
    color: var(--white-color) !important;
    font-weight: 500 !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected:hover {
    background-color: var(--primary-color-hover) !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    min-height: 100p;
    width: auto;
    padding: 8px 24px !important;
    margin: 8px !important;
    border-radius: 100px !important;
    background-color: var(--primary-color-bg) !important;
    color: var(--primary-color) !important;
    font-weight: 500 !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
    background-color: var(--primary-color-bg-hover) !important;
} */
