.section-footer {
    background-color: var(--title-color);
    color: var(--white-color);
}

.section-footer .important-links {
    display: flex;
    align-items: top;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.section-footer .important-links h6 {
    margin: 0px 16px;
    color: var(--title-color-bg);
    font-weight: 300;

}

.section-footer .socialmedia img {
    margin: 0px 20px 48px 20px;
}

.section-footer h3:hover {
    color: var(--primary-color);
    cursor: pointer;
}

.section-footer li {
    color: var(--title-color-bg);
    margin-top: 8px;
}

.section-footer li:hover {
    color: var(--primary-color);
    cursor: pointer;
}

.footer-link a {
    text-decoration: none;
}

.section-footer a {
    color: var(--white-color);
    text-decoration: none;
}

.section-footer a:hover {
    color: var(--primary-colorr);
}

.links{
    display: flex;
    flex-direction: column;
}

.copy-text{
    display: flex;
    justify-content: center;
    color: var(--title-color-bg);
}

.footer-row .col{
    display: flex;
    align-items: flex-start;
}