/* .css-1wlk0hk-MuiAvatar-root{

    width: 120px !important;
    height: 120px !important;
}
.css-1pqm26d-MuiAvatar-img {
    width: 120px !important;
    height: 120px !important;
    text-align: center;
    object-fit: cover;
    color: transparent;
    text-indent: 10000px;
} */

.section-toolbar {
    display: flex;
    justify-content: space-between;
}

.section-toolbar a {
    color: var(--white-color);
    padding: 8px 6px;
}

.section-toolbar a:hover {
    background-color: #ffffff48;
    color: var(--white-color);
    border-radius: 100%;

}

.section-toolbar button:hover {
    background-color: #ffffff48;
    color: var(--white-color);
}

/* .css-hyum1k-MuiToolbar-root{
    background-color: var(--primary-color);
} */

.css-1f2xuhi-MuiDrawer-docked .MuiDrawer-paper {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 10px;
}

.custom-pagination {
    margin-top: 20px;
    align-items: baseline;
}

.form-check label {
    display: inline-block;
    font-size: 16px !important;
    font-weight: 400 !important;
    color: var(--secondary-color);
}

/* 
span {
    margin: 8px !important;
    font-weight: 700 !important;
    font-size: 20px !important;
    color: var(--secondary-color);
} */

.DrawerItems a {
    color: var(--secondary-color);
}

.DrawerItems span{
    margin: 0px !important;
    font-weight: 400 !important;
    font-size: 16px !important;
}

.DrawerItems a:hover {
    color: var(--primary-color);
}
.admin-sidebar .form-select{
    border: none;
    color: #283739;
} 
.admin-sidebar .form-select:focus{
    border: none;
    box-shadow: none;
    color: #283739;
} 


/* .section-toolbar .css-p28jxa-MuiButtonBase-root-MuiIconButton-root{
    background: none !important;
} */

/* .section-toolbar .language .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    border: none !important;
    color: var(--white-color);
} */

/* .section-toolbar .language .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    color: var(--white-color);
} */

.section-toolbar .language .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
    color: var(--white-color);
}

/* .section-toolbar .language .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select{
    width: 60px;
} */