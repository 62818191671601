.section-aboutus{
    margin-top: 48px;
}

.section-aboutus h1{
    color: var(--title-color);
    font-weight: 700;
    font-size: 40px;
}

.section-aboutus p{
    font-size: 16px;
    color: var(--secondary-color);
    line-height: 28px;
}

.section-aboutus strong{
    font-size: 24px;
    color: var(--primary-color);
}

.title{
    font-size: 3.5rem;
    text-align: center;
}

.headerText {
    font-size: 18px;
}

.bannerImg {
    width: 100%;
    height: 400px;
    border-radius: 10px;
}

.column {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media only screen and (min-width: 768px) {
    /* tablets and desktop */
}

@media only screen and (max-width: 767px) {
    /* phones */
    .title{
        font-size: 2rem;
    }

    .headerText {
        font-size: 16px;
    }

    
    .bannerImg {
        width: 100%;
        height: 300px;
    }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
    /* portrait phones */
}