.contact-us-header.{
  padding: 0%;
  margin: 0%;
  overflow: hidden;
}

.form-check label {
  font-size: 15px;
  font-style: normal;
  font-weight: 200;
}
.form-check {
  display: inline;
}
.text-center-contact {
  text-align: center;
}
.contact-container {
  height: 100%;
  padding-top: 4%;
  padding-bottom: 4%;
}
.text-black {
  color: black;
}
.carousel-slide-contact {
  display: flex;
  justify-content: center;
  height: 65vh; /* Set the slide to take the full viewport height */
  overflow: hidden;
}
.carousel-caption {
  color: white;
  top: 65% !important;
}
.text-bold-contact {
  font-weight: 700;
  font-size: 64px;
}
.img-height-contact {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.flex-center-contact {
  display: flex;
  justify-content: center;
  align-items: start;
}

.text-center-span {
  text-align: center;
  display: flex;
  justify-content: center;
}
.cards {
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
}

.icons {
  height: 64px;
}

.card-body p {
  font-size: 16px;
}

.card-body strong {
  font-size: 20px;
  color: var(--title-color);
  margin: 0;
}

.no-focus-shadow:focus {
  background: none;
  box-shadow: 0 0 0 3px var(--primary-color) !important;
}
.btn-contact {
  padding: 16px !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  width: 40% !important;
}
.p-xs-5 {
  padding: 48px;
}
.text-edited-contact {
  padding: 0;
  margin: 0;
  margin-top: 4px;
}

@media (max-width: 992px) {
  .flex-center-contact {
    display: flex;
    margin-bottom: 32px;
    margin-top: 32px;
    height: 100%;
    justify-content: center;
    flex-direction: row;
    align-items: start;
  }
  .mb-xs-4 {
    margin-bottom: 0px;
  }
  .carousel-caption {
    color: white;
    top: 50% !important;
  }
  .text-bold-contact {
    font-weight: 700;
    font-size: 32px;
    width: 100%;
  }
  .icons {
    height: 56px;
  }
  .p-xs-5 {
    padding: 32px;
  }

  .btn-contact {
    padding: 16px !important;
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
    width: 50% !important;
  }
}

@media (max-width: 767px) {
  .mb-xs-4 {
    margin-bottom: 24px;
  }

  .btn-contact {
    padding: 16px !important;
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
    width: 100% !important;
  }
}

@media (max-width: 575px) {
  .flex-center-contact {
    display: flex;
    margin-bottom: 32px;
    margin-top: 32px;
    flex-direction: column;
  }
  .text-center-contact {
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .text-edited-contact {
    text-align: left;
    padding: 0;
    margin: 0;
    margin-left: 24px;
  }
  .icons {
    height: 40px;
  }
  .mb-xs-4 {
    margin-bottom: 24px;
  }
  .carousel-caption {
    color: white;
    top: 45% !important;
  }
  .mb-xs-5 {
    margin-bottom: 40px;
  }
  .p-xs-5 {
    padding: 24px;
  }
}
