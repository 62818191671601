tr {
  margin-bottom: 8px;
}

.img-box {
  overflow: hidden;
  height: 240px;
  width: 200px;
}

.product-img {
  height: 200px;
  transition: 0.3s ease-in-out;
}

.product-img:hover {
  transform: scale(1.4);
  z-index: 2;
  transition: 0.3s ease-in-out;
}

.product-nav-item{
  margin-top:130px;
}

.product-nav-item .nav-link {
  color: var(--secondary-color);
}

.product-nav-item .nav-item .active {
  color: white;
  border-bottom: 1px solid white;
}

.product-nav-item .nav-link:hover {
  color: white !important;
  border-bottom: 1px solid white;
}

.main-area .css-1mxz8qt-MuiPaper-root {
  box-shadow: none !important;
}

.main-area {
  display: flex;
}
nav li a {
  color: black !important;
  text-decoration: none !important;
}

.card {
  border: 1px solid black !important;
}
.main-area .filter {
  width: 20%;
  padding-right: 20px;
}

.accordion-button:focus {
  box-shadow: none !important;
  background-color: none !important;
}
.accordion-button:not(.collapsed) {
  background-color: transparent !important;
  color: #253745 !important;
}

.main-area .accordion-button:not(.collapsed)::after {
  background-image: url(../../public/images/icons/uparrow.png);
}

.counter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.counter input {
  width: 50px;
  border: 0;
  /* line-height: 30px; */
  font-size: 20px;
  text-align: center;
  background: var(--secondary-color);
  color: var(--white-color);
  appearance: none;
  outline: 0;
}
.counter span {
  display: block;
  font-size: 20px;
  padding: 0 10px;
  cursor: pointer;
  color: var(--secondary-color);
  user-select: none;
  border: 1px solid var(--secondary-color);
}

tbody .btn-success {
  background-color: var(--primary-color);
  border: none;
  font-size: 20px;
  padding: 12px 16px;
  font-weight: 500;
}

tbody thead th {
  color: var(--primary-color) !important;
  font-size: 18px !important;
}

tbody td {
  color: var(--secondary-color) !important;
}

.css-ke5b6m-MuiButtonBase-root-MuiButton-root {
  color: var(--primary-color) !important;
  background-color: var(--primary-color-bg) !important;
  border: none !important;
  padding: 12px 18px !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  box-shadow: none !important;
}

.css-ke5b6m-MuiButtonBase-root-MuiButton-root:hover {
  background-color: var(--primary-color) !important;
  cursor: pointer !important;
  color: var(--white-color) !important;
}

.css-sxix9q-MuiButtonBase-root-MuiButton-root {
  color: var(--white-color) !important;
  background-color: var(--title-color-bg) !important;
  border: none !important;
  padding: 12px 20px !important;
  font-weight: 700 !important;
  font-size: 16px !important;
}

.css-sxix9q-MuiButtonBase-root-MuiButton-root:hover {
  color: var(--white-color) !important;
  background-color: var(--secondary-color) !important;
  cursor: pointer !important;
}

.cust-nav {
  background-color: #253745 !important;
}

.txt-highlight {
  color: var(--primary-color);
  font-size: 12px !important;
}

.title-text {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.txt-price {
  font-size: 20px !important;
  font-weight: 700 !important;
  color: var(--secondary-color) !important;
}
.color {
  color: var(--primary-color);
}
.txt-cutprice {
  text-decoration: line-through;
}

.border-right-3 {
  border-right: 1px solid var(--bs-border-color) !important;
}
.accordion {
  border: none !important;
  --bs-accordion-border-color: none !important;
  --bs-accordion-border-width: none !important;
}
.accordion-item {
  border-bottom: 1px solid var(--bs-border-color) !important;
}

.accordion-button {
  padding: 16px 0px !important;
}

.accordion-collapse .collapse .show {
  display: block !important;
}
.accordion-body {
  padding: 0 0 8px 16px !important;
}

.product-nav-item .top-navigation {
  position: absolute;
  width: 100%;
}
.custom-indicator {
  background-color: white !important; /* Set the indicator color to white or any other color */
  height: 3px !important; /* Customize the height of the indicator as needed */
}


@media (max-width: 768px) {
  .main-area {
    display: block;
  }

  .main-area .filter {
    width: 100%;
    padding-right: 0px;
    margin-bottom: 40px;
  }

  .main-area .css-150c1kg-MuiPaper-root {
    width: 100%;
  }

  .css-m1xwsv-MuiTableCell-root {
    padding: 16px !important;
  }
  .css-11w94w9-MuiTableCell-root {
    text-align: right !important;
  }
}

@media (max-width: 599px) {
  .product-nav-item{
    margin-top:180px;
  }
}
