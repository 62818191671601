/* td p{
    min-width: max-content;
    margin-bottom: 0%;
    color: var(--secondary-color);
} */

/* .stock a{
    color: var(--white-color);
    text-decoration: none;
}

.stock a:hover{
    color: var(--white-color);
    text-decoration: none;
} */

.stock{
    
}

.filter .btn-success {
    background-color: var(--primary-color);
    border: none;
    padding: 16px 24px;
    font-weight: 700;
    font-size: 20px;
}

.filter .btn-success:hover {
    background-color: var(--primary-color-hover);
}

.filter .btn-secondary {
    background-color: var(--title-color-bg) ;
    border: none;
    padding: 16px 24px;
    font-weight: 700;
    font-size: 20px;
}

.filter .btn-secondary:hover {
    background-color: var(--secondary-color);
}

.main-area .accordion-button:not(.collapsed) {
    background-color: #f2f2f2;
    color: var(--title-color);
    font-weight: 700;
}

.main-area .accordion-button{

    font-weight: 700;
}

.main-area  .accordion-body {
    color: var(--secondary-color);
}

.stock h2{
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    color: var(--primary-color);
}

.form-check-input:focus {
    border: 1px solid var(--primary-color) !important;
    box-shadow: none !important;
}

.form-check-input:checked {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}

.show-more{
    color: #111;
    background-color: #B9BFC348;
    border: 2px solid #949eb280;
    border-radius: 8px;
    font-weight: 700;
    font-size: 15px;
    cursor: pointer;
    padding: 10px 16px;
}

.show-more:hover{
    color: #111;
    background-color: #B9BFC380;
}

/* .show-more{
    color: #949eb2;
    background: none;
    border: none;
    border-bottom: 1px solid #111;
    margin-top: 8px;
}

.show-more:hover{
    color: #111;
} */