.section-forgot-password .btn-success {
    background-color: var(--primary-color) !important;
    border: none !important;
    padding: 18px 48px !important;
    font-size: 18px !important;
    font-weight: 700 !important;
}

.section-forgot-password .btn-success:hover {
    background-color: var(--primary-color-hover) !important;
}

.section-forgot-password a {
    color: var(--primary-color);
    text-decoration: none;
}

.section-forgot-password a:hover {
    color: var(--primary-color-hover);
}

.section-forgot-password .formFieldErrorMessage {
    color: var(--red-color);
}