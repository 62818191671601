.modal-header{
    border-bottom: none !important;
}

.modal h1{
    font-weight: 700 !important;
    color: var(--title-color) !important;
    margin-bottom: 0 !important;
}

.modal p{
    color: var(--secondary-color);
}

.modal a{
    color: var(--primary-color) !important;
    text-decoration: none;
}
.modal-open .text-center{
    text-align: center;
    font-size: 16px;
}

.modal .btn-success{
    background-color: var(--primary-color) !important;
    border: none !important;
    width: 100%;
    border-radius: 12px !important;
    padding: 16px 32px !important;
    font-size: 18px !important;
    font-weight: 700 !important;
}

.modal .btn-success:hover{
    background-color: var(--primary-color-hover) !important;
}

.modal .formFieldError {
    border: 1px solid var(--red-color);
  }
  
.modal .formFieldErrorMessage {
    color: var(--red-color);
    text-align: left;
}