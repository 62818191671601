.top-navigation .top-navigation-row {
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  color: var(--white-color);
}

.top-navigation .last-col {
  display: flex;
  justify-content: flex-end;
}

.icon-envelope {
  margin-right: 15px; /* Adjust margins as needed */
  font-size: 20px;
}

.last-col .form-select {
  background: none;
  border: none;
  color: #949eb2;
}
.last-col .form-select:focus {
  background: none;
  border: none;
  color: #949eb2;
  box-shadow: none;
}

.custom-form {
  padding: 16px;
  width: 300px;
}

.form-select:focus {
  background: none;
  border: none;
  color: var(--white-color);
  box-shadow: none;
}

.main-navigation .flex-between {
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 40px;
  background-color: white;
  padding: 16px;
}

.main-navigation .btn-success {
  background-color: var(--primary-color);
  border: none;
  padding: 12px 32px;
  font-size: 18px;
  font-weight: 700;
}

.main-navigation .btn-outline-secondary {
  background-color: var(--primary-color-bg);
  padding: 16px;
  border-radius: 100px;
  border: none;
}

.main-navigation .btn-outline-secondary:hover {
  background-color: var(--primary-color-bg-hover);
  cursor: pointer;
}

.form-check .form-check-input[type="radio"] {
  border-radius: 50%;
  font-size: 20px;
  box-shadow: none;
}

.form-check label {
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  margin-left: 0px;
}

/* .user-area .btn-success{
    
    background: none;
    border: none;
}

.user-area .btn-success:hover{
    background-color: var(--primary-color);
} */

.user-area .btn-navbar {
  margin-right: 4px;
  background-color: var(--primary-color);
  border: none;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border-width: 1px;
  color: white;
  height: 40px;
  border-radius: 0.375rem;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
}
.logo-design {
  margin-right: 15px; /* Adjust margins as needed */
  font-size: 20px;
}

.user-area .btn-navbar:active {
  background-color: var(--primary-color-hover);
}

.user-area .btn-navbar:hover {
  background-color: var(--primary-color-hover);
  cursor: pointer;
}

.custom-navbar a {
  color: #949eb2;
}

.custom-navbar a:hover {
  color: #36AE7c;
}

.custom-navbar .dropdown-menu a {
  color: var(--secondary-color);
}

.custom-navbar .dropdown-menu a:hover {
  color: var(--title-color);
}

.custom-navbar .dropdown-menu a:focus {
  background-color: var(--primary-color);
}

.custom-navbar #first-grid{
  order: 1;
}

.custom-navbar #second-grid{
  order: 2;
}

.custom-navbar #third-grid{
  order: 3;
}

.last-col .language select option {
  color: var(--secondary-color);
}

.main-navigation .css-whxibi-MuiBadge-badge {
  background-color: var(--primary-color);
}

.main-navigation #responsive-navbar-nav .css-1c32n2y-MuiBadge-root {
  background-color: #dcdfe1;
  padding: 12px;
  border-radius: 4px;
}

.main-navigation #responsive-navbar-nav .css-1c32n2y-MuiBadge-root:hover {
  background-color: var(--title-color-bg);
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
} 



@media (max-width: 767px) {
  .top-navigation .top-navigation-row {
    background-color: var(--primary-color);
    padding: 8px 0px;
    color: var(--white-color);
  }

  .top-navigation .top-navigation-row .last-col {
    margin-top: 32px;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 599px) {
  .custom-navbar #first-grid{
    order: 1;
  }
  
  .custom-navbar #second-grid{
    order: 3;
  }
  
  .custom-navbar #third-grid{
    order: 2;
  }
}
