.agentNav .btn-success {
    background-color: var(--primary-color) !important;
    border: none !important;
    padding: 16px 24px !important;
    font-weight: 700 !important;
    font-size: 18px !important;
}

.agentNav .btn-success:hover {
    background-color: var(--primary-color-hover) !important;
}

.img-box {
    overflow: hidden;
    height: 140px;
    width: 100px;
}

.product-img {
    height: 100px;
    width: 100px;
    transition: 0.3s ease-in-out;
}

.agentNav button a{
    color: var(--white-color);
    text-decoration: none;
}

.storageLink a{
    color: var(--secondary-color);
}

.nav{
    --bs-nav-link-color: var(--primary-color) !important;
    --bs-nav-link-hover-color: var(--primary-color) !important;
}

.storageLink .focus{
    color: var(--primary-color);
    border-bottom: 1px solid var(--primary-color);
}

.storageLink .active{
    color: var(--primary-color);
    border-bottom: 1px solid var(--primary-color);
}

.storageLink:hover{
    color: var(--primary-color);
    border-bottom: 1px solid var(--primary-color);
} 
