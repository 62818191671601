/* Carousel */

.costome-carousel h3 {
  font-size: 4rem;
}

.costome-carousel video {
  height: 600px;
  -o-object-fit: cover;
  object-fit: cover;
}

.carousel-caption {
  display: flex !important;
  flex-direction: column !important;
  position: absolute;
  right: 15% !important;
  bottom: 12rem !important;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: var(--white-color);
  text-align: center;
}
.flex-center {
  display: flex;
  justify-content: center;
}
.flex-center .display-flex {
  display: flex;
  align-items: center;
  background: linear-gradient(to right, #f7f7f7, white);
  padding: 16px;
  border-radius: 150px;
  width: 100%;
}

.img-flex {
  display: flex;
  flex-direction: row;
}

.bg-color {
  background-color: var(--primary-color) !important;
  padding: 48px;
}

.section-install {
  margin-top: 100px;
}

.text-white {
  color: white;
}
.text-bold-h1 {
  font-weight: 800;
}

.text-bold {
  font-weight: 700;
  font-size: 18px;
}

.flex-center .icon-box {
  background-color: white;
  padding: 28px;
  border-radius: 50%;
}
.flex-center .box-text {
  padding-left: 16px;
  display: flex;
  flex-direction: column;
}
.flex-center .heading-text {
  font-size: 32px;
  font-weight: 800;
  color: var(--primary-color) !important;
}
.text-center {
  text-align: center;
  font-weight: 800;
  font-size: 48px;
}
.margin-bottom {
  margin-bottom: 62px;
}
.text-edited {
  color: #7a7a7a;
  text-align: center;
}
.center-div {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}
.btn-edit {
  border: none;
  padding: 6px 16px;
  font-weight: 500;
  width: 20%;
  border-radius: 4px;
}
.section-service {
  display: flex;
  align-items: center;
  margin-top: 100px;
}

.col {
  display: flex;
  align-items: center;
}

.section-why-choose {
  margin-top: 100px;
}

.section-service .column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section-service .column small {
  display: inline;
  width: 88px;
  border-radius: 4px;
  padding-left: 12px;
  color: var(--primary-color) !important;
  background-color: var(--primary-color-bg);
  align-items: center;
  font-weight: 500;
  font-size: 16px;
}

.section-service .column h1 {
  font-size: 40px;
  font-weight: 700;
  color: var(--title-color);
  margin-top: 8px;
  margin-bottom: 0%;
}

.section-service .column p {
  color: var(--secondary-color);
  font-size: 16px;
  line-height: 28px;
  margin-top: 0px;
  margin-bottom: 24px;
}

.section-service .column .all-list {
  margin-top: 8px;
}

.service-content h1 {
  font-weight: 700;
}

.service-box {
  background-color: #fff;
  padding: 16px;
}

.service-img {
  text-align: center;
  display: flex;
  align-items: center;
}

.service-main h6 {
  margin-top: 16px;
  margin-bottom: 0px;
}

.service-main span {
  font-size: 14px;
}

.section-service .column .list {
  display: flex;
  align-items: center;
  margin-top: 0px;
}

.section-service .column .list span {
  color: var(--title-color);
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin-left: 8px;
}

.section-service .column .learnMore {
  margin-top: 32px;
  width: 240px;
  padding: 16px 32px;
  border-radius: 8px;
}

.section-service .column .button-icon {
  margin-left: 8px;
}

.section-service .learnMore {
  background-color: var(--primary-color) !important;
  border: none;
  padding: 12px 32px;
  font-size: 16px;
  font-weight: 700;
  color: white;
  border-radius: 8px;
  margin-top: 32px;
}

.section-service .learnMore:active {
  background-color: var(--primary-color-hover);
}

.section-service .learnMore:hover {
  background-color: var(--primary-color-hover);
  cursor: pointer;
}
.text-center-span-1 {
  text-align: left;
  color: #7a7a7a;
}
.carousel-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Set the slide to take the full viewport height */
  overflow: hidden; /* Hide any overflow content */
}

.full-height-image {
  height: 100%; /* Make the image take the full height of its parent container */
  width: 100%; /* Maintain the image's aspect ratio */
  object-fit: cover; /* Ensure the image covers the entire container while maintaining its aspect ratio */
}

@media (max-width: 767px) {
  .img-flex {
    display: flex;
    flex-direction: column;
  }
  .section-service .reverse {
    display: flex;
    flex-direction: column-reverse;
  }
  .text-center-home {
    text-align: center;
  }
  .text-center-span-1 {
    text-align: center;
    color: #7a7a7a;
  }

  .section-service .column {
    margin-top: 32px;
  }
  .btn-edit {
    border: none;
    padding: 6px 16px;
    width: 45%;
    border-radius: 4px;
  }
}
