body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-color: #36AE7C;
  --primary-color-hover: #25875e;

  --primary-color-bg: #FEF1E6;
  --primary-color-bg-hover: #ffdcc0;

  --title-color:#283739;
  --title-color-bg: #B9BFC3;

  --red-color: #E64848;
  --red-color-hover: #bf2b2b;

  --green-color: #3CCF4E;
  --green-color-hover: #1F8A70;


  --secondary-color: #747F88;

  --white-color: #fff;

}