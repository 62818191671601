.section-register h1{
    color: var(--title-color);
    font-weight: 800;
    font-size: 48px;
}

.section-register .text-muted{
    color: var(--secondary-color) !important;
}

.section-register p{
    color: var(--secondary-color);
}

.section-register a{
    color: var(--primary-color) !important;
    font-weight: 500;
}

.section-register .btn-success{
    background-color: var(--primary-color) !important;
    border: none !important;
    padding: 18px 48px !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    width: 100%;
}

.section-register .btn-success:hover{
    background-color: var(--primary-color-hover) !important;
}

.section-register .form-check-input:checked {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.section-register .form-check-input:checked[type=radio] {
    background-image: none !important;
}

.section-register .formFieldError {
    border: 1px solid var(--red-color);
  }
  
.section-register .formFieldErrorMessage {
    color: var(--red-color);
}

.sec-signin p{
    color: var(--secondary-color);
font-size: 16px;
font-weight: 400;
}
  